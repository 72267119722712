import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider
} from "../../firebase/firebase";

import axios from 'util/Api';
import qs from 'qs';
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  CHANGE_PASSWORD,
  CHANGE_AGENT,
  OTP_VERIFIED,
  OTP_RESEND,
  FORGET_PASSWORD_EMAIL,
  FORGET_PASSWORD_VERIFICATION,
  RESET_PASSWORD,
    EDIT_PROFILE,
    GET_DASHBOARD
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess,
    userSignUpSuccess,otpVerified,otpUserError,
    otpResend,forgetPasswordEmailSuccess,
  resetPasswordSuccess,forgetPasswordVerificationSuccess,
    userEditProfileSuccess,getDashboardSuccess} from "../../appRedux/actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess
} from "../actions/Auth";


const createUserWithEmailPasswordRequest = async (firstName,lastName,email,password) =>
  await axios.post('agent/register',
    qs.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    })
  ).then((response) => {
     return response.data;

  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  });

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await axios.post('agent/login',
      qs.stringify({
        email: email,
        password: password,
      })
    ).then((response) => {
        localStorage.setItem('token', response.data.data.token);
        return response.data;
    }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
    });

const changePasswordRequest = async (email, password, current) =>
  await axios.put('agent/change-password',
    qs.stringify({
      email: email,
      password: password,
      current:current
    })
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(function (error) {
    if(error.response != null) {
      console.log(error.response);
      return error.response.data;
    }
    else {
      return error;
    }
  });

const resetPasswordRequest = async (email, password, confirm) =>
  await axios.post('agent/recoverypassword',
    qs.stringify({
      email: email,
      password: password,
      confirmPassword:confirm
    },
)
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(function (error) {
    if(error.response != null) {
      console.log(error.response);
      return error.response.data;
    }
    else {
      return error;
    }
  });




const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await  auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await  auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await  auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {email, password, firstName, lastName } = payload.user;
  const { history } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, firstName, lastName, email, password );
    if (signUpUser.status === 0) {
      if(signUpUser.data) {
        yield put(showAuthMessage(signUpUser.data[0].msg));
      }
      else{
        yield put(showAuthMessage(signUpUser.message));
      }
    } else if (signUpUser.status === 1) {
      localStorage.setItem('emailForSignUp', signUpUser.data.email);
      yield put(userSignUpSuccess(signUpUser.data._id));
      history.push('/OTP')
    }
    else {
      yield put(showAuthMessage(signUpUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.status === 0) {
      if(signInUser.message === 'Account is not confirmed. Please confirm your account.'){
        localStorage.setItem('emailForSignUp',email)
        history.push('/OTP')
      }
      yield put(showAuthMessage(signInUser.message));
    } else if (signInUser.status === 1) {
      axios.defaults.headers.common['access-token'] = "Bearer " + signInUser.data.token;
      localStorage.setItem('user_id', signInUser.data._id);
      // localStorage.setItem('token', signInUser.data.token);
      yield put(userSignInSuccess(signInUser.data));
      history.push('/')
    }

    else {

        yield put(showAuthMessage(signInUser));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
}

function* changeUserPassword({payload}) {
  console.log(payload);
  const {email, password, current} = payload;
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const result = yield call(changePasswordRequest, email, password, current);
    console.log(result);
    if (result.status === 0) {
      yield put(showAuthMessage(result.message));
    } else if (result.status === 1) {
      yield put(showAuthMessage(result.message));
    }
    else {
        yield put(showAuthMessage(result));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
}

function* ResetUserPassword({payload}) {
  console.log(payload);
  const { email, password,confirm } = payload.ChangePassword;
  const { history } = payload;
  console.log(email, password,confirm)
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const result = yield call(resetPasswordRequest, email, password, confirm);
    console.log(result);
    if (result.status === 0) {
      yield put(showAuthMessage(result.message));
    } else if (result.status === 1) {
      yield put(resetPasswordSuccess(result.data));
      history.push('/signin')
    }
    else {
      yield put(showAuthMessage(result));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const changeAgentRequest = async (email, agent) =>
  await axios.put('auth/change-agent',
    qs.stringify({
      email: email,
      agent:agent
    })
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(function (error) {
    if(error.response != null) {
      console.log(error.response);
      return error.response.data;
    }
    else {
      return error;
    }
  });


const otpVerifyRequest = async (email,otp) =>
  await axios.post('agent/verify-otp',
    qs.stringify({
      email: email,
      otp: otp,
    })
  ).then((response) => {
    return response.data;

  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  });

const otpResendRequest = async (email) =>
  await axios.post('agent/resend-verify-otp',
    qs.stringify({
      email: email,
    },console.log(email))

  ).then((response) => {
    console.log(response)
    return response.data;

  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  });

const forgetPasswordEmailRequest = async (email) =>
  await axios.post('agent/change-otp',
    qs.stringify({
      email: email,
    },console.log(email))

  ).then((response) => {
    console.log(response)
    return response.data;

  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  });

const forgetPasswordVerificationRequest = async (email,otp) =>
  await axios.post('agent/otp-verification',
    qs.stringify({
      email: email,
      otp: otp,
    })
  ).then((response) => {
    return response.data;

  }).catch(function (error) {
    if(error.response != null) {
      return error.response.data;
    }
    else {
      return error;
    }
  })

function* changeUserAgent({payload}) {
  console.log(payload);
  const {email, agent} = payload;
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const result = yield call(changeAgentRequest, email, agent);
    console.log(result);
    if (result.status === 0) {
      yield put(showAuthMessage(result.message));
    } else if (result.status === 1) {
      yield put(showAuthMessage(result.message));
    }
    else {
        yield put(showAuthMessage(result));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('token');
      localStorage.clear();
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// otp verification

function* otpverification({payload}) {
  const { email, otp } = payload.user;
  const { history } = payload;
  try {
    const otpUser = yield call(otpVerifyRequest, email, otp );
    if (otpUser.status === 0) {
      if(otpUser.message === 'Account already confirmed.'){
        history.push('/signin')
      }
      if(otpUser.data) {
        yield put(showAuthMessage(otpUser.data[0].msg));
      }
      else{
        yield put(showAuthMessage(otpUser.message));
      }
    }
    else if (otpUser.status === 1) {
      history.push('/signin')
      yield put(otpVerified(otpUser.data._id));

    }
    else {
      yield put(otpUserError(otpUser));
    }
  } catch (error) {
    yield put(otpUserError(error));
  }
}


function* otpResending({payload}) {
  const { user } = payload;
  console.log("first func saga: ",user);
  const { history } = payload;
  try {
    const otpResendUser = yield call(otpResendRequest, user );
    if (otpResendUser.status === 0) {
      if(otpResendUser.data) {
        yield put(showAuthMessage(otpResendUser.data[0].msg));
      }
      else{
        yield put(showAuthMessage(otpResendUser.message));
      }
    }
    else if (otpResendUser.status === 1) {
      yield put(otpResend(otpResendUser.data._id));
      console.log(history)
    }
    else {
      console.log("ERROR IN yeilding")
      // yield put(otpUserError(otpResendUser));
    }
  } catch (error) {
    console.log(error)
    // yield put(otpUserError(error));
  }
}

function* forgetPasswordEmailResending({payload}) {
  const { user } = payload;
  console.log("first func saga: ",user);
  const { history } = payload;
  try {
    const otpResendUser = yield call(forgetPasswordEmailRequest, user );
    if (otpResendUser.status === 0) {
      if(otpResendUser.data) {
        yield put(showAuthMessage(otpResendUser.data[0].msg));
      }
      else{
        yield put(showAuthMessage(otpResendUser.message));
      }
      localStorage.clear();
    }
    else if (otpResendUser.status === 1) {
      yield put(forgetPasswordEmailSuccess(otpResendUser));
      console.log(history)
      console.log("User is for email: ",user)
      history.push('/ForgetPasswordOTP')

    }
    else {
      console.log("ERROR IN yeilding")
      // yield put(otpUserError(otpResendUser));
    }
  } catch (error) {
    console.log(error)
    // yield put(otpUserError(error));
  }
}



function* forgetPasswordVerificationNow({payload}) {
  const { email, otp } = payload.user;
  console.log("first func saga: ",email, otp);
  const { history } = payload;
  try {
    const otpResendUser = yield call(forgetPasswordVerificationRequest, email,otp );
    if (otpResendUser.status === 0) {
      if(otpResendUser.data) {
        yield put(showAuthMessage(otpResendUser.data[0].msg));
      }
      else{
        yield put(showAuthMessage(otpResendUser.message));
      }
    }
    else if (otpResendUser.status === 1) {
      yield put(forgetPasswordVerificationSuccess(otpResendUser));
      console.log(history)
      console.log("User is for email: ",email)
      history.push('/ResetPassword')

    }
    else {
      console.log("ERROR IN yeilding")
      // yield put(otpUserError(otpResendUser));
    }
  } catch (error) {
    console.log(error)
    // yield put(otpUserError(error));
  }
}

//me
const getDashboardNowAsync = async () =>
    await axios.get('agent/me/'
    ).then((response) => {
        return response.data;
    }).catch(function (error) {
        if(error.response != null) {
            return error.response.data;
        }
        else {
            return error;
        }
    });

function* getDashboardNow() {

    try {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
        const authUser = yield call(getDashboardNowAsync);
        if (authUser.status === 0) {
            yield put(showAuthMessage(authUser.message));
        }
        else if (authUser.status === 1) {
            yield put(getDashboardSuccess(authUser.data));
        }
        else {
            yield put(showAuthMessage(authUser));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* watchGetDashboard() {
    yield takeEvery(GET_DASHBOARD, getDashboardNow);
}

//Edit Profile
const editprofileNowAsync = async (brokerage,brokerageWebsite,license,licenseNo,phone) =>
    await axios.post('agent/',
        qs.stringify({
            brokerage: brokerage,
            brokerageWebsite: brokerageWebsite,
            license: license,
            licenseNo: licenseNo,
            phone: phone
        })
    ).then((response) => {
        return response.data;

    }).catch(function (error) {
        if(error.response != null) {
            return error.response.data;
        }
        else {
            return error;
        }
    });


function* editprofileNow({payload}) {
    const {brokerage,brokerageWebsite,license,licenseNo,phone } = payload.data;
    const {history } = payload;
    console.log(payload.data);
    try {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
        const Profileuser = yield call(editprofileNowAsync, brokerage,brokerageWebsite,license,licenseNo,phone );
        if (Profileuser.status === 1) {
            if(Profileuser.data) {
                yield put(userEditProfileSuccess(Profileuser.data));
                history.push("/app/dashboard");
            }
        }
        else {
            yield put(showAuthMessage(Profileuser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}



export function* editProfile() {
    yield takeEvery(EDIT_PROFILE, editprofileNow);
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* userChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changeUserPassword);
}

export function* userChangeAgent() {
  yield takeEvery(CHANGE_AGENT, changeUserAgent);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

// Otp process
export function* watchotpVerified() {
  yield takeEvery(OTP_VERIFIED, otpverification);
}

//otp resend
export function* watchotpResend() {
  yield takeEvery(OTP_RESEND, otpResending);
}

//watchforgetPasswordEmail
export function* watchforgetPasswordEmail() {
  yield takeEvery(FORGET_PASSWORD_EMAIL, forgetPasswordEmailResending);
}

//watchforgetPasswordVerification
export function* watchforgetPasswordVerification() {
  yield takeEvery(FORGET_PASSWORD_VERIFICATION, forgetPasswordVerificationNow);
}

//
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, ResetUserPassword);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(userChangePassword),
    fork(userChangeAgent),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),
    fork(watchotpVerified),
    fork(watchotpResend),
    fork(watchforgetPasswordEmail),
    fork(watchforgetPasswordVerification),
    fork(watchResetPassword),
    fork(editProfile),
    fork(watchGetDashboard),

  ]);
}
