import React, {Component} from "react";
import {parsePhoneNumber} from 'libphonenumber-js'
import {Button, Checkbox, Form, Icon, Input, message,Select} from "antd";

import {
    userChangePassword,
    hideMessage,
    showAuthLoader,
    userEditProfile
} from "../../../appRedux/actions/Auth";
import {connect} from "react-redux";


const { Option } = Select;
const FormItem = Form.Item;

class ProfileEdit extends Component {

    state = {
        confirmDirty: false,
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['brokerage','brokerageWebsite','license', 'licenseNo','phone'],(err, values) => {
            if (!err) {
                this.props.showAuthLoader();
                this.props.userEditProfile(values,this.props.history);
            }
        });
    };

    handleSubmit1 = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['agent', 'email'],(err, values) => {
            if (!err) {
                this.props.showAuthLoader();
                this.props.userChangeAgent(values,this.props.history);
            }
        });
    };

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
                this.props.form.resetFields();
            }, 100);
        }
    }


    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.props.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    }
    onChange=(value)=> {
        console.log(`selected ${value}`);
    }

   onBlur=()=> {
        console.log('blur');
    }

    onFocus=()=> {
        console.log('focus');
    }

     onSearch=(val)=> {
        console.log('search:', val);
    }
    handlePhone = () => {
      const {setFieldsValue,getFieldValue} = this.props.form;
      const phone = getFieldValue('phone');
      const pattern = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
      let newPhone,newPhonee;

      if (phone !== "")
      {
        if (phone.toString().match(pattern))
        {
          newPhone = parsePhoneNumber('+1' + phone);
          newPhonee = newPhone ? newPhone.formatNational() : "";
          setFieldsValue({
            phone : newPhonee
          })

        }
        else
        {
          newPhonee = "";
          setFieldsValue({
            phone : newPhonee
          })
        }

      }
      else {
        newPhonee = "";
        setFieldsValue({
          phone : newPhonee
        })
      }

    }
    render() {

        const {getFieldDecorator,handleSelect} = this.props.form;
        // const { values, handleChange,handleSelect, form: {getFieldDecorator} } = props;
        const {showMessage, loader, alertMessage} = this.props;

        return (
            <div className="gx-login-container">
              <div className="gx-login-content">
                <div className="gx-login-header gx-text-center">
                  <h1 className="gx-login-title">Edit Profile</h1>
                </div>
                <Form onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">

                  <Form.Item>
                      {getFieldDecorator('brokerage',{
                        initialValue: this.props.authUser.agentProfile && this.props.authUser.agentProfile.brokerage,
                          rules: [
                              {
                                  required: true,
                                  message: 'Please Enter Brokerage'
                              }
                          ],
                      })(
                          <Input
                              placeholder="Brokerage"
                              type="text"/>
                      )}
                  </Form.Item>
                  <Form.Item>
                      {getFieldDecorator('brokerageWebsite',{
                        initialValue: this.props.authUser.agentProfile && this.props.authUser.agentProfile.brokerageWebsite,
                          rules: [
                              {
                                  required: true,
                                  message: 'Please Enter Brokerage Website'
                              }
                          ],
                      })(
                          <Input
                              placeholder="Brokerage Website"
                              type="text"/>
                      )}
                  </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('license', {
                          initialValue: this.props.authUser.agentProfile && this.props.authUser.agentProfile.license,
                            rules: [{ required: true, message: 'Please Enter State License' }],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                placeholder="State License"
                                onChange={this.onChange("State License")}
                                filterOption={(input, option) =>{
                                    if(option.props.children) {
                                     return   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }else{
                                        console.log(option)
                                    }
                                }
                                }
                            >

                                <Option value="AL">Alabama</Option>
                                <Option value="AK">Alaska</Option>
                                <Option value="AZ">Arizona</Option>
                                <Option value="AR">Arkansas</Option>
                                <Option value="CA">California</Option>
                                <Option value="CO">Colorado</Option>
                                <Option value="CT">Connecticut</Option>
                                <Option value="DE">Delaware</Option>
                                <Option value="DC">District of Columbia</Option>
                                <Option value="FL">Florida</Option>
                                <Option value="GA">Georgia</Option>
                                <Option value="HI">Hawaii</Option>
                                <Option value="ID">Idaho</Option>
                                <Option value="IL">Illinois</Option>
                                <Option value="IN">Indiana</Option>
                                <Option value="IA">Iowa</Option>
                                <Option value="KS">Kansas</Option>
                                <Option value="KY">Kentucky</Option>
                                <Option value="LA">Louisiana</Option>
                                <Option value="ME">Maine</Option>
                                <Option value="MD">Maryland</Option>
                                <Option value="MA">Massachusetts</Option>
                                <Option value="MI">Michigan</Option>
                                <Option value="MN">Minnesota</Option>
                                <Option value="MS">Mississippi</Option>
                                <Option value="MO">Missouri</Option>
                                <Option value="MT">Montana</Option>
                                <Option value="NE">Nebraska</Option>
                                <Option value="NV">Nevada</Option>
                                <Option value="NH">New Hampshire</Option>
                                <Option value="NJ">New Jersey</Option>
                                <Option value="NM">New Mexico</Option>
                                <Option value="NY">New York</Option>
                                <Option value="NC">North Carolina</Option>
                                <Option value="ND">North Dakota</Option>
                                <Option value="OH">Ohio</Option>
                                <Option value="OK">Oklahoma</Option>
                                <Option value="OR">Oregon</Option>
                                <Option value="PA">Pennsylvania</Option>
                                <Option value="RI">Rhode Island</Option>
                                <Option value="SC">South Carolina</Option>
                                <Option value="SD">South Dakota</Option>
                                <Option value="TN">Tennessee</Option>
                                <Option value="TX">Texas</Option>
                                <Option value="UT">Utah</Option>
                                <Option value="VT">Vermont</Option>
                                <Option value="VA">Virginia</Option>
                                <Option value="WA">Washington</Option>
                                <Option value="WV">West Virginia</Option>
                                <Option value="WI">Wisconsin</Option>
                                <Option value="WY">Wyoming</Option>
                            </Select>
                        )}
                    </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('licenseNo',{
                      initialValue: this.props.authUser.agentProfile && this.props.authUser.agentProfile.licenseNo,
                        rules: [
                            {
                                required: true,
                                message: 'Please Enter License #'
                            }
                        ],
                    })(
                        <Input
                            placeholder="License #"
                            type="text"/>
                    )}
                </Form.Item>
                  <Form.Item>
                    {getFieldDecorator('phone',{
                      initialValue: this.props.authUser.agentProfile && this.props.authUser.agentProfile.phone,
                        rules: [
                            {
                                required: true,
                                message: 'Please Enter Valid Phone',
                              pattern : '^\\(?([0-9]{0,3})\\)?[-. ]?([0-9]{0,3})[-. ]?([0-9]{0,4})$'
                            }
                        ],
                    })(
                        <Input
                            placeholder="Phone #"
                            type="text"
                            onBlur = {this.handlePhone}
                        />

                    )}
                </Form.Item>

                  {/*<FormItem>*/}
                      {/*{getFieldDecorator('current', {*/}
                          {/*rules: [*/}
                              {/*{*/}
                                  {/*required: true,*/}
                                  {/*message: 'Please input your current Password!'*/}
                              {/*}*/}
                          {/*],*/}
                      {/*})(*/}
                          {/*<Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"*/}
                                          {/*placeholder="Current Password"/>*/}
                      {/*)}*/}
                  {/*</FormItem>*/}
                  {/*<FormItem hasFeedback>*/}
                      {/*{getFieldDecorator('password', {*/}
                          {/*rules: [*/}
                              {/*{*/}
                                  {/*required: true,*/}
                                  {/*message: 'Please input your new Password!'*/}
                              {/*},*/}
                              {/*{*/}
                                  {/*validator: this.validateToNextPassword,*/}
                              {/*},*/}
                          {/*],*/}
                      {/*})(*/}
                          {/*<Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"*/}
                                          {/*placeholder="New Password" onBlur={this.handleConfirmBlur}/>*/}
                      {/*)}*/}
                  {/*</FormItem>*/}
                  {/*<FormItem hasFeedback>*/}
                      {/*{getFieldDecorator('confirm', {*/}
                          {/*rules: [*/}
                              {/*{*/}
                                  {/*required: true, message: 'Please confirm your new Password!'*/}
                              {/*},*/}
                              {/*{*/}
                                  {/*validator: this.compareToFirstPassword,*/}
                              {/*},*/}
                          {/*],*/}
                      {/*})(*/}
                          {/*<Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"*/}
                                          {/*placeholder="Confirm New Password" onBlur={this.handleConfirmBlur}/>*/}
                      {/*)}*/}
                  {/*</FormItem>*/}


                    {/*{loader ?
              <div>
                <CircularProgress className={'gx-loader-view'}/>
              </div> : null}*/}
                    {showMessage ?
                        message.info(alertMessage.toString()) : null}
                  <FormItem className="gx-text-center">
                    <Button
                        loading={loader}
                        type="primary"
                        htmlType="submit">
                      Submit
                    </Button>
                  </FormItem>
                </Form>

              </div>

            </div>
        );
    }
}

const WrappedNormalSignUpForm = Form.create()(ProfileEdit);

const mapStateToProps = ({auth}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
    userChangePassword,
    hideMessage,
    showAuthLoader,
    userEditProfile
})(WrappedNormalSignUpForm);
