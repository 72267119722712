import React from "react";
import {Card} from "antd";
import './propertycss/BuyersList.css'
import {
  getAllBuyers,
  getAllBuyersSuccess,
    getrecentBuyers
  // getBuyerProperties,
  // getBuyerPropertiesSuccess
} from "../../../appRedux/actions";
import {connect} from "react-redux";
import Widget from "../../../components/Widget";
import {Link, Redirect} from "react-router-dom";
import {Table} from 'reactstrap';
import GetBuyerProperties from "./getBuyerProperties";
const {Column} = Table;

class BuyersList extends React.Component {

  state = {
    isClicked : false
  }

  componentDidMount() {
    this.props.getAllBuyers();
    this.props.getrecentBuyers();
    this.props.getAllBuyersSuccess();
  }

  handleClick = (id) => {
   localStorage.setItem("buyerProporties",id)
   this.setState({isClicked : true})
  }



  render() {
    const columns = [
      {
      title: 'First Name',
      dataIndex: 'firstName',
      render: (phone) => {
        return <span className="h6">{phone && phone}</span>
      },
    },{
      title: 'Last Name',
      dataIndex: 'lastName',
      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    }, {
      title: 'Email',
      dataIndex: 'email',
      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    }, {
      title: 'Phone',
      dataIndex: 'buyerProfile.phone',
      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    }];

    let BuyerProperties;
    if (this.props.recentbuyers)
    {
      BuyerProperties =  <div className="table-responsive">

        <Table hover size="md" className="text-center" style={{color:'#535353', fontSize:'11px'}}>
          <thead>

          <tr >
            <td >FIRST NAME</td>
            <td>LAST NAME</td>
            <td>EMAIL</td>
            <td >PHONE</td>
          </tr>

          </thead>
          <tbody>

          {this.props.recentbuyers.map((data,index) =>{
            return   <tr style={{cursor:'pointer'}} onClick={() => this.handleClick(data._id)} key={index}>
              <td>{data.firstName}</td>
              <td>{data.lastName}</td>
              <td>{data.email}</td>
              <td>{data.buyerProfile && data.buyerProfile.phone ? data.buyerProfile.phone: "N/A"}</td>
            </tr>

          })}
          </tbody>
        </Table>
      </div>
    }
    else
    {
      BuyerProperties =  <div className="h6">
        <Table hover size="md" style={{color:'#535353', fontSize:'11px'}}>
          <thead>
          <tr >
            <td>FIRST NAME</td>
            <td>LAST NAME</td>
            <td>EMAIL</td>
            <td>PHONE</td>
          </tr>
          </thead>
        </Table>
        <div className="text-center">
          <span style={{color:'#535353'}}>No Data Found</span>
        </div>

      </div>
    }

    return (
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Buyers</h2>
        } extra={
        <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
          <i className="icon icon-forward gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/>
            <Link to="buyers"> Buyer Dashboard
            </Link>
        </p>
      }>

        {
          this.state.isClicked === false ?
            BuyerProperties
            :
            <Redirect to="/app/listing" />
        }

      </Widget>
    );
  }

}

const mapStateToProps = ({property}) => {
  const {data, loader,recentbuyers} = property;
  return {data, loader,recentbuyers}
};
export default connect(mapStateToProps, {getAllBuyers,getAllBuyersSuccess,getrecentBuyers})(BuyersList);
