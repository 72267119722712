import React, { useRef, useEffect } from "react";

import ReceivedMessageCell from "./ReceivedMessageCell/index";
import SentMessageCell from "./SentMessageCell/index";
import {connect} from "react-redux";
import {getBuyerProperties, getDashboard} from "../../../appRedux/actions";

const Conversation = ({conversationData, selectedUser, authUser}) => {
  const {replies} = conversationData;

  const messageRef = useRef();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          block: 'end',
          inline: 'nearest'
        })
    }
  })

    return (
      <div ref={messageRef}>
        {
          replies && replies.map((conversation, index) =>

            conversation.from.toString() === authUser._id.toString() ?
              <SentMessageCell key={index} conversation={conversation}/>
              :
              <ReceivedMessageCell key={index} conversation={conversation} user={selectedUser}/>

          )
        }

      </div>

    )

};

function parseJwt(token) {
  if (!token) { return; }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}


const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(Conversation);

