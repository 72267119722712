import React, {Component} from "react";
import {Col, Row} from "antd";
import About from "../../../components/profile/AboutUser/index";
import Contact from "../../../components/profile/ContactUser/index";
import Auxiliary from "../../../util/Auxiliary";
import ProfileHeader from "../../../components/profile/ProfileHeader/index";


class UserProfileindex extends Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    console.log(this.props.data)
    // this.props.getAllProfile();
  }

  render() {
    return (
      <Auxiliary>
        <ProfileHeader/>
        <div className="gx-profile-content">
          <Row>
            <Col xl={16} lg={14} md={14} sm={24} xs={24}>
              <About />

            </Col>

            <Col xl={8} lg={10} md={10} sm={24} xs={24}>
              <Contact/>

            </Col>
          </Row>
        </div>
      </Auxiliary>
    );
  }
}


export default UserProfileindex;


