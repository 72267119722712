import React from "react";
import Widget from "components/Widget";
import {connect} from "react-redux";

const Contact = ({clintProfile}) => {
  return (
    <Widget title="Your Agent" styleName="gx-card-profile-sm">
      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-user gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Name</span>
          <p className="gx-mb-0">
            {clintProfile.agent && clintProfile.agent.firstName} {clintProfile.agent && clintProfile.agent.lastName}

          </p>
        </div>
      </div>

      <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
        <div className="gx-mr-3">
          <i className="icon icon-email gx-fs-xxl gx-text-grey"/>
        </div>
        <div className="gx-media-body">
          <span className="gx-mb-0 gx-text-grey gx-fs-sm">Email</span>
          <p className="gx-mb-0">
              {clintProfile.agent && clintProfile.agent.email}</p>
        </div>
      </div>

    </Widget>
  )
}

const mapStateToProps = ({profile}) => {
    const {clintProfile} = profile;
    return {clintProfile}
};
export default connect(mapStateToProps, null)(Contact);
