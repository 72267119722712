import React from "react";
import {Avatar, Menu} from "antd";
import {connect} from "react-redux";
import { Icon } from 'antd';
import {Link} from "react-router-dom";
const ProfileHeader = ({authUser}) => {
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top">
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar style={{ color: '#54B579', backgroundColor: '#fff' }} icon={<Icon type="user" />}
                      className="gx-size-90" alt=""/>
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">{authUser && authUser.firstName} {authUser && authUser.lastName} <Icon type="safety-certificate" theme="filled" /></h2>
              <p className="gx-mb-0 gx-fs-lg">{authUser && authUser.email} <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
              </p>
            </div>
          </div>
          <div className="gx-profile-banner-top-right">
            <ul className="gx-follower-list">

          </ul>
          </div>
        </div>
        <div className="gx-profile-banner-bottom">
          <div className="gx-tab-list">

          </div>

          <div style={{display:'flex',flexDirection: 'row', width:'100%'}}>
              <div>
                {authUser && authUser.agentProfile &&
                <Link to="/app/editprofile"><i className="icon icon-edit"/>
                  &nbsp;Edit Profile
                </Link>
                }
              </div>
              <div style={{marginLeft:'auto'}}>

                <span><Link to="/app/changepassword"><i className="icon icon-edit"/>
                  &nbsp;Change password
          </Link></span>

              </div>



          </div>




        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(ProfileHeader);
