import React from "react";
import {Route, Switch} from "react-router-dom";
import Dashboard from "./dashboard";
import Profile from "./profile";
import Setting from "./setting";
import BuyerListData from "./dashboard/BuyersListData"
import asyncComponent from "../../util/asyncComponent";
import ProfileEdit from "./profileEdit"
import GetBuyerProperties from "./dashboard/getBuyerProperties";
import PropertyDetail from "./dashboard/propertyDetail";
import UserProfile from "./dashboard/UserProfileindex";
import Chat from "../../components/chat";

const App = ({match}) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard}/>
    <Route path={`${match.url}/profile`} component={Profile}/>
    <Route path={`${match.url}/editprofile`} component={ProfileEdit}/>
    <Route path={`${match.url}/changepassword`} component={Setting}/>
    <Route path={`${match.url}/buyers`} component={BuyerListData}/>
    <Route path={`${match.url}/listing`} component={GetBuyerProperties}/>
    <Route path={`${match.url}/detail`} component={PropertyDetail}/>
    <Route path={`${match.url}/userProfile`} component={UserProfile}/>
    <Route path={`${match.url}/comments`} component={Chat} exact/>
    <Route path={`${match.url}/algolia`} component={asyncComponent(() => import('../algolia'))}/>
  </Switch>
);

export default App;
