import React, {Component} from "react";
import {Avatar, Button, Drawer, Input, Tabs} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import './Chat.css'

import {
getDashboard,
  getBuyerProperties,
  getClintProfile,
  getAllBuyers,
  postReplies
} from "../../appRedux/actions";

import ChatUserList from "./ChatUserList/index";
import conversationList from "./data/conversationList";
import Conversation from "./Conversation/index";
import users from "./data/chatUsers";
import ContactList from "./ContactList/index";
import IntlMessages from "../../util/IntlMessages";
import SearchBox from "../SearchBox";
import CircularProgress from "../../components/CircularProgress/index";
import {connect} from "react-redux";
import ChatBuyerList from "./ChatBuyerList";
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai/index";

const TabPane = Tabs.TabPane;

class Chat extends Component {
  filterContact = (userName) => {
    if (userName === '') {
      return users.filter(user => !user.recent);
    }
    return users.filter((user) =>
      !user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  filterUsers = (userName) => {
    if (userName === '') {
      return users.filter(user => user.recent);
    }
    return users.filter((user) =>
      user.recent && user.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
    );
  };
  Communication = () => {
    const {message, selectedUser, conversation, selectedSectionId} = this.state;
    // const {conversationData} = conversation;
    // const {data} = this.props.data;
    console.log("Communication mein conversation", conversation)
    return <div className="gx-chat-main">
      <div className="gx-chat-main-header">
        <span className="gx-d-block gx-d-lg-none gx-chat-btn"><i className="gx-icon-btn icon icon-chat"
                                                                 onClick={this.onToggleDrawer.bind(this)}/></span>
        <div className="gx-chat-main-header-info">

          <div className="gx-chat-avatar gx-mr-2">
            <div className="gx-status-pos">
              <Avatar src={selectedUser.garage}
                      className="gx-rounded-circle gx-size-40"
                      alt=""/>

              {/*<span className={`gx-status gx-${selectedUser.status}`}/>*/}
            </div>
          </div>

          <div className="gx-chat-contact-name">
            {selectedUser.address}
          </div>
        </div>

      </div>

      <CustomScrollbars className="gx-chat-list-scroll scroll-color">
        <Conversation conversationData={
          // [
          //   {
          //     'type': 'received',
          //     'message': 'All the Lorem Ipsum generators on the',
          //     'sentAt': '3:12:45 PM',
          //   },
          // ]
          this.state.conversation && this.state.conversation
        }
                      selectedSectionId = {selectedSectionId}
                      selectedUser={selectedUser}
                      /*authUser = {this.props.authUser && this.props.authUser._id}*/
        />


      </CustomScrollbars>

      <div className="gx-chat-main-footer">
        <div className="gx-flex-row gx-align-items-center" style={{maxHeight: 51}}>
          <div className="gx-col">
            <div className="gx-form-group">
                            <input
                              id="required" className="gx-border-0 ant-input gx-chat-textarea"
                              onKeyUp={this._handleKeyPress.bind(this)}
                              onChange={this.updateMessageValue.bind(this)}
                              value={message}
                              placeholder="Type and hit enter to send message"
                            />
            </div>
          </div>
          <i className="gx-icon-btn icon icon-sent" onClick={this.submitComment.bind(this)}/>
        </div>
      </div>
    </div>
  };

  AppUsersInfo = () => {
    return <div className="gx-chat-sidenav-main">
      <div className="gx-bg-grey-light gx-chat-sidenav-header">

        <div className="gx-chat-user-hd gx-mb-0">
          <i className="gx-icon-btn icon icon-arrow-left" onClick={() => {
            this.setState({userState: 1});
          }}/>

        </div>
        {/*<div className="gx-chat-user gx-chat-user-center">*/}
        {/*  <div className="gx-chat-avatar gx-mx-auto">*/}
        {/*    <Avatar src='https://via.placeholder.com/150x150'*/}
        {/*            className="gx-size-60" alt="John Doe"/>*/}
        {/*  </div>*/}

        {/*  <div className="gx-user-name h4 gx-my-2">Robert Johnson</div>*/}

        {/*</div>*/}
      </div>
      <div className="gx-chat-sidenav-content">

        {/*<CustomScrollbars className="gx-chat-sidenav-scroll">*/}
        {/*  <div className="gx-p-4">*/}
        {/*    <form>*/}
        {/*      <div className="gx-form-group gx-mt-4">*/}
        {/*        <label>Mood</label>*/}

        {/*        <Input*/}
        {/*          fullWidth*/}
        {/*          id="exampleTextarea"*/}
        {/*          multiline*/}
        {/*          rows={3}*/}
        {/*          onKeyUp={this._handleKeyPress.bind(this)}*/}
        {/*          onChange={this.updateMessageValue.bind(this)}*/}
        {/*          defaultValue="it's a status....not your diary..."*/}
        {/*          placeholder="Status"*/}
        {/*          margin="none"/>*/}

        {/*      </div>*/}
        {/*    </form>*/}
        {/*  </div>*/}
        {/*</CustomScrollbars>*/}

      </div>
    </div>
  };
  ChatUsers = () => {
    // console.log("Chat Users", this.props.data)

    return <div className="gx-chat-sidenav-main">

      {/*<div className="gx-chat-sidenav-header scroll-color">*/}

      {/*  <div className="gx-chat-user-hd">*/}

      {/*    /!*<div className="gx-chat-avatar gx-mr-3" onClick={() => {*!/*/}
      {/*    /!*  this.setState({*!/*/}
      {/*    /!*    userState: 2*!/*/}
      {/*    /!*  });*!/*/}
      {/*    /!*}}>*!/*/}
      {/*    /!*  <div className="gx-status-pos">*!/*/}
      {/*    /!*    /!*<Avatar id="avatar-button" src='https://via.placeholder.com/150x150'*!/*!/*/}
      {/*    /!*    /!*        className="gx-size-50"*!/*!/*/}
      {/*    /!*    /!*        alt=""/>*!/*!/*/}
      {/*    /!*    <Avatar style={{ color: '#fff', backgroundColor: '#7c7e7d' }}*!/*/}
      {/*    /!*            className="gx-size-50" alt="">{this.props.clintProfile && this.props.clintProfile.firstName && this.props.clintProfile.firstName.charAt(0)}</Avatar>*!/*/}
      {/*    */}
      {/*    /!*    /!*<span className="gx-status gx-online"/>*!/*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*    /!*</div>*!/*/}

      {/*    /!*<div className="gx-module-user-info gx-flex-column gx-justify-content-center">*!/*/}
      {/*    /!*  <div className="gx-module-title">*!/*/}
      {/*    /!*    <h5 className="gx-mb-0">{this.props.clintProfile &&  this.props.clintProfile.firstName} {this.props.clintProfile &&  this.props.clintProfile.lastName}</h5>*!/*/}
      {/*    /!*  </div>*!/*/}
      {/*      /!*<div className="gx-module-user-detail">*!/*/}
      {/*      /!*  <span className="gx-text-grey gx-link">robert@example.com</span>*!/*/}
      {/*      /!*</div>*!/*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  /!*<div className="gx-chat-search-wrapper">*!/*/}

      {/*  /!*  <SearchBox styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"*!/*/}
      {/*  /!*             placeholder="Search or start new chat"*!/*/}
      {/*  /!*             onChange={this.updateSearchChatUser.bind(this)}*!/*/}
      {/*  /!*             value={this.state.searchChatUser}/>*!/*/}

      {/*  /!*</div>*!/*/}
      {/*</div>*/}

      {
        this.state.chatState === 0 ?
          <div className="gx-chat-sidenav-content">
            {/*<AppBar position="static" className="no-shadow chat-tabs-header">*/}
            <Tabs className="gx-card-tabs" defaultActiveKey="1">
              <TabPane  tab={<span>All Buyers</span>} key="1">
                <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1" >
                  { this.props.data && this.props.data === 0 ?
                    <div className="gx-p-5 gx-text-center">{this.state.userNotFound}</div>
                    :
                    <ChatBuyerList chatUsers={this.props.data && this.props.data}
                                  chatStateId={() => { this.setState({chatState : 1})}}
                                   onSelectBuyer={this.onSelectBuyer.bind(this)}/>
                    // "Chat Users List"
                  }
                </CustomScrollbars>
              </TabPane>

            </Tabs>


          </div>

          :

          <div className="gx-chat-sidenav-content">
            <Button style={{marginTop: '15px', marginLeft:'15px'}}  type="primary" onClick={() => {this.setState({chatState : 0})}}>
                <AiOutlineLeft style={{marginBottom:2, textAlign:'center'}} />
                <span>Back</span>
            </Button>
            {/*<AppBar position="static" className="no-shadow chat-tabs-header">*/}
            <Tabs className="gx-tabs-half" defaultActiveKey="1">
              <TabPane  tab={<span>Recent</span>} key="1">
                <CustomScrollbars className="gx-chat-sidenav-scroll-tab-1" >
                  { this.props.buyerProperties && this.props.buyerProperties === 0 ?
                    <div className="gx-p-5 gx-text-center">{this.state.userNotFound}</div>
                    :
                    <ChatUserList chatUsers={this.props.buyerProperties ? this.props.buyerProperties.filter((data) => data.replies.length > 0) : []}
                                  selectedSectionId={this.state.selectedSectionId}
                                  onSelectUser={this.onSelectUser.bind(this)}/>
                    // "Chat Users List"
                  }
                </CustomScrollbars>
              </TabPane>
              <TabPane  tab={<span>All Properties</span>} key="2">
                <CustomScrollbars className="gx-chat-sidenav-scroll-tab-2">
                  {
                    this.props.buyerProperties && this.props.buyerProperties.length === 0 ?
                      <div className="gx-p-5 text-center">{this.state.userNotFound}</div>
                      :
                      <ContactList contactList={this.props.buyerProperties && this.props.buyerProperties}
                                   selectedSectionId={this.state.selectedSectionId}
                                   onSelectUser={this.onSelectUser.bind(this)}/>
                  }
                </CustomScrollbars>
              </TabPane>
            </Tabs>


          </div>
      }

    </div>
  };
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  handleChange = (event, value) => {
    this.setState({selectedTabIndex: value});
  };

  handleChangeIndex = index => {
    this.setState({selectedTabIndex: index});
  };
  onSelectUser = async (user) => {
    await this.setState({
      loader: true,
      selectedSectionId: user._id,
      drawerState: this.props.drawerState,
      selectedUser: user,
      conversation: this.props.buyerProperties.find((data) => data._id === user._id)
    });
    console.log("Conversationssaasfg", this.state.conversation)
    await setTimeout(() => {
      this.setState({loader: false});
    }, 1500);
  };

  onSelectBuyer = async (id) => {
    await this.props.getBuyerProperties(id,this.props.history)
    await this.props.getClintProfile(id,this.props.history)
  };
  showCommunication = () => {
    return (
      <div className="gx-chat-box">
        {this.state.selectedUser === null ?
          <div className="gx-comment-box">
            <div className="gx-fs-80"><i className="icon icon-chat gx-text-muted"/></div>
            <h1 className="gx-text-muted">{<IntlMessages id="chat.selectUserChat"/>}</h1>
            <Button className="gx-d-block gx-d-lg-none" type="primary"
                    onClick={this.onToggleDrawer.bind(this)}>{<IntlMessages
              id="chat.selectContactChat"/>}</Button>

          </div>
          : this.Communication()}
      </div>)
  };

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      userNotFound: 'No property found',
      drawerState: false,
      selectedSectionId: '',
      selectedTabIndex: 1,
      chatState : 0,
      userState: 1,
      searchChatUser: '',
      contactList: users.filter((user) => !user.recent),
      selectedUser: null,
      message: '',
      chatUsers: users.filter((user) => user.recent),
      conversationList: null,
      conversation: [],
      selectedBuyerProperties : null
    }
  }

  componentDidMount() {
     this.props.getDashboard()
    this.props.getAllBuyers()
  }

  submitComment() {
    if (this.state.message !== '') {
      // const updatedConversation = this.state.conversation.conversationData.concat({
      //
      //   message: this.state.message,
      //   // 'sentAt': Moment().format('hh:mm:ss A'),
      // });
      // this.setState({
      //   conversation: {
      //     ...this.state.conversation, conversation: updatedConversation
      //
      //   },
      //   message: '',
      //   conversationList: this.state.conversationList.map(conversationData => {
      //     if (conversationData.id === this.state.conversation.id) {
      //       return {...this.state.conversation, conversationData: updatedConversation};
      //     } else {
      //       return conversationData;
      //     }
      //   })
      // });
      const updatedConversation = {
          propertyId : this.state.selectedSectionId,
          message : this.state.message
        }


      console.log("Data to be submitted", updatedConversation)
      this.props.postReplies(updatedConversation,this.props.history)
      this.setState({message : ''})
    }

  }

  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value
    });
  }

  updateSearchChatUser(evt) {
    this.setState({
      searchChatUser: evt.target.value,
      contactList: this.filterContact(evt.target.value),
      chatUsers: this.filterUsers(evt.target.value)
    });
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const {loader, userState, drawerState} = this.state;
    // console.log("Buyer Properties",this.props.buyerProperties)
    return (
        <div className="gx-main-content">
          <div className="gx-app-module gx-chat-module">
            <div className="gx-chat-module-box">
              <div className="gx-d-block gx-d-lg-none">
                <Drawer
                  placement="left"
                  closable={false}
                  visible={drawerState}
                  onClose={this.onToggleDrawer.bind(this)}>
                  {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
                </Drawer>
              </div>
              <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
                {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
              </div>
              {loader ?
                <div className="gx-loader-view">
                  <CircularProgress/>
                </div> : this.showCommunication()
              }
            </div>
          </div>
        </div>

    )
  }
}


const mapStateToProps = ({property,auth,profile}) => {
  const {data, buyerProperties, loader} = property;
  const {authUser} = auth;
  const {clintProfile} = profile;
  return {data, buyerProperties, authUser, clintProfile, loader}
};
export default connect(mapStateToProps, {getBuyerProperties, getDashboard,getClintProfile,getAllBuyers,postReplies})(Chat);
