import {GET_ALL_BUYERS , GET_ALL_BUYERS_SUCCESS, GET_ALL_BUYERS_ERROR,
  GET_BUYER_PROPERTIES,
  GET_BUYER_PROPERTIES_SUCCESS,
  GET_BUYER_PROPERTIES_ERROR,
    GET_RECENT_BUYERS,GET_RECENT_BUYERS_SUCCESS,GET_RECENT_BUYERS_ERROR,

} from "constants/ActionTypes";
import {POST_REPLIES, POST_REPLIES_ERROR, POST_REPLIES_SUCCESS} from "../../constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  data: [],
  buyerProperties:[],
  recentbuyers:[],
  replies : []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUYER_PROPERTIES: {return {...state, loader: true,}}
    case GET_BUYER_PROPERTIES_SUCCESS: {return {...state, loader: false, buyerProperties:action.payload.data}}
    case GET_BUYER_PROPERTIES_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}


    case GET_ALL_BUYERS: {return {...state, loader: true,}}
    case GET_ALL_BUYERS_SUCCESS: {return {...state, loader: false, data:action.payload}}
    case GET_ALL_BUYERS_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    case GET_RECENT_BUYERS: {return {...state, loader: true,}}
    case GET_RECENT_BUYERS_SUCCESS: {return {...state, loader: false, recentbuyers:action.payload}}
    case GET_RECENT_BUYERS_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}

    case POST_REPLIES: {return {...state, loader: true,}}
    case POST_REPLIES_SUCCESS: {return {...state, loader: false, replies:action.payload}}
    case POST_REPLIES_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}


    default:
      return state;
  }
}
