import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import notesSagas from "./Notes";
import propertySagas from "./Property"
import profileSagas from "./Profile"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    notesSagas(),
    propertySagas(),
    profileSagas()
  ]);
}
