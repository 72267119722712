import {GET_ALL_PROFILE,GET_CLINT_PROFILE,
    GET_CLINT_PROFILE_Success } from "constants/ActionTypes";
import axios from 'util/Api';
import {getAllProfile,getAllProfileSuccess,getAllProfileError,showMessage,getClintProfileSuccess} from "../../appRedux/actions/Profile"
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import qs from 'qs'


const getallProfileNowAsync = async () =>
  await axios.get('buyerprofile/')
    .then((response) => {
      console.log(response);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }
      else {
        return error;
      }
    });

function* getAllProfileNow(){
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const profile = yield call(getallProfileNowAsync);
    if (profile.status === 0) {
      yield put(getAllProfileError(profile.message));
    } else if (profile.status === 1) {
      console.log("status 1");
      yield put(getAllProfileSuccess(profile.data));
    }
    else {
      console.log("else");
      yield put(getAllProfileError(profile.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(getAllProfileError(error));
  }

}

export function* watchgetallprofile() {
  yield takeEvery(GET_ALL_PROFILE, getAllProfileNow);
}



//Get Clint Profile
const getClintProfileNowAsync = async (userid) =>
    await  axios.post('admin/clientProfile',qs.stringify({id:userid}))
        .then(({data})=>{
            // console.log(data)
            return data

        })
        .catch((err)=>{
            console.log(err)
            return err
        });


function* getClintProfileNow(id) {
    const {payload} = id;
    try {

        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
        const ClintProfile = yield call(getClintProfileNowAsync,payload );


        if(ClintProfile) {
            yield put(getClintProfileSuccess(ClintProfile.data));
        }
        else {
            yield put(showMessage('SomeThing Went Wrong'));
        }

    } catch (error) {
        yield put(showMessage(error));
    }
}


export function* watchgetClintProfile() {
    yield takeEvery(GET_CLINT_PROFILE,getClintProfileNow);
}


export default function* rootSaga() {
  yield all([
    fork(watchgetallprofile),
    fork(watchgetClintProfile)
  ])

}
