import React from "react";
import Widget from "../../../components/Widget/index";
import {Col, Row} from "antd";
import {connect} from "react-redux";

const NameCard = ({authUser}) => {
  return (
    <Widget styleName="gx-green-lime-gradient-reverse gx-text-white">
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <h1 className="gx-font-weight-semi-bold gx-text-white">Hi, {authUser && authUser.firstName}</h1>
          <h5 className="gx-font-weight-semi-bold gx-text-white gx-text-capitalize">Wherever you are, we hope you're staying safe.</h5>
          <h6 className="gx-text-white gx-text-capitalize">- Your Friends at Realtism</h6>
        </Col>
      </Row>
    </Widget>
  );
};

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(NameCard);
