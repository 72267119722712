import React from "react";
import {Button, Card, Table, Tooltip, Modal, Skeleton, Input, Icon, Row, Col, Avatar} from "antd";
import {
  getBuyerProperties,
  getBuyerPropertiesSuccess,
  getClintProfile,
} from "../../../appRedux/actions";
import {connect} from "react-redux";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import {AiOutlineLeft} from "react-icons/ai";
import {NavLink} from "react-router-dom";
import ProfileModal from "../../../components/Modals/ProfileModal"
import About from "../../../components/profile/AboutUser"
import ReactDragListView from "react-drag-listview";
import Imgmodal from "./imgmodal"
import moment from 'moment';

function getColorCode(overall) {
  if(overall === ""){
    return '#515756';
  }else if(overall <= 2){
    return '#FF0700';
  }else if(overall <= 4 && overall >= 3){
    return '#FF7800';
  }else if(overall <= 6 && overall >= 5){
    return '#ffce1f';
  }else if(overall <= 8 && overall >= 7){
    return '#FEF100';
  } else if(overall <= 10 && overall >= 9){
    return '#80D726';
  }
}
function getNullNumber(overall) {
  if(overall === ""){
    return 'x';
  }else if(overall >= 0){
    return overall;
  }
}
class GetBuyerProperties extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      data: null,

      imageModalVisible: false,
      modalOpen: false,
      garage: "",

      columns: [
        {
          title: 'Last modified',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
          render: (lastModified) => (
            <div>{lastModified ? moment(lastModified).fromNow() : ""}</div>
          )
        },
        {
          title: "Thumbnail",
          dataIndex: "garage",
          key: "garage",
          render: (garage) => (
            <span>

            <img
              className="gx-img-thumbnail"
                 src={garage}
                 alt="realtism"
                 onClick={()=>this.setImageModal(garage)}
                 style={{height: '60px', 'border-radius': '10px'}}
            />


            <Modal
              centered
              onCancel={()=>this.setCloseImageModal()}
              title="Preview"
              style={{top: 20}}
              visible={this.state.imageModalVisible}
              footer={null}

            >

                <img
                  className="gx-img-fluid-full"
                  src={this.state.garage}
                  alt="realtism"/>
            </Modal>
          </span>

          )
        },

        {
          title: 'MLS',
          dataIndex: 'mls',
          key: 'mls',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.mls.localeCompare(b.mls),
          ...this.getColumnSearchProps('mls', ''),
        },
        {
          title: 'DOM',
          dataIndex: 'DOMdate',
          key: 'DOMdate',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.DOMdate.localeCompare(b.DOMdate),
          ...this.getColumnSearchProps('DOMdate', ''),
          render: (DOMdate) => (
            <div>{DOMdate ? moment(DOMdate).fromNow() : ""}</div>
          )
        },
        {
          title: 'Status',
          dataIndex: 'mStatus',
          key: 'mStatus',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.mStatus.localeCompare(b.mStatus),
          ...this.getColumnSearchProps('status', ''),
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.price.localeCompare(b.price),
          ...this.getColumnSearchProps('price', '(000,00)'),
        },

        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.address.localeCompare(b.address),
          ...this.getColumnSearchProps('address', ''),
        },
        {
          title: 'Requested Tour',
          dataIndex: 'question.qtour',
          key: 'question.qtour',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qtour.localeCompare(b.question.qtour),
          // ...this.getColumnSearchProps('question.qtour', ''),
          render: (tour) => (
            <div>   {tour ? tour : "N/A"}</div>
          )
        },
        {
          title: 'Overall',
          dataIndex: 'question.qoverall',
          key: 'question.qoverall',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qoverall.localeCompare(b.question.qoverall),
          // ...this.getColumnSearchProps('question.qoverall', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
        }, {
          title: 'Price',
          dataIndex: 'question.qprice',
          key: 'question.qprice',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qprice.localeCompare(b.question.qprice),
          // ...this.getColumnSearchProps('question.qprice', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
        }, {
          title: 'Location',
          dataIndex: 'question.qlocation',
          key: 'question.qlocation',
          align: 'center',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => a.question.qlocation.localeCompare(b.question.qlocation),
          // ...this.getColumnSearchProps('question.qlocation', ''),
          render: (overall) => (
            <Avatar style={{color: '#fff', backgroundColor: getColorCode(overall)}} className="gx-size-20"
                    alt="">{getNullNumber(overall)}</Avatar>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'question.qcomments',
          key: 'question.qcomments',
          align: 'center',
          render: (comments) => (
            <span>
            {/*<a
              onClick={()=>this.commentsModal(id)}
            >
            <i className='icon icon-chat'/>
            </a>*/}
                <Tooltip title={comments ? comments : "No comments..."}>
                  <i className='icon icon-chat'/>
              </Tooltip>
        </span>
          )
        },
        {
          title: 'Details',
          dataIndex: '_id',
          key: '_id',
          align: 'center',
          render: (id) => (
            <span>
            <a onClick={() => {
              this.propertyDetail(id)
            }}>
              <Link to="detail">
              <Tooltip title="Open Property">
                <i className='icon icon-exclamation'/>
            </Tooltip>
              </Link></a>

        </span>
          )
        }, {
          title: 'Link',
          dataIndex: 'link',
          key: 'link',
          align: 'center',
          render: (link) => (
            <span>
            <a href={link} target="_blank"><Tooltip title="Link to Property">
                <i className='icon icon-link'/>
            </Tooltip></a>
        </span>
          )
        },

      ],

    }
    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns
        });
        localStorage.setItem("columns", columns);
      },
      nodeSelector: "th"
    };
    this.props.getClintProfile(localStorage.getItem("buyerProporties"), this.props.history);
  }

  state = {
    filteredInfo: null,
    sortedInfo: null,

  };

  setImageModal=(data) =>{


    this.setState({
      imageModalVisible: true,
      garage: data
    });
   }

  setCloseImageModal= e => {
    this.setState({
      imageModalVisible: false,
      count: 0
    });
  }


  componentDidMount() {
    console.log("Did Mount", localStorage.getItem("buyerProporties"))
    this.props.getBuyerProperties(localStorage.getItem("buyerProporties"), this.props.history);
    // this.props.getBuyerProperties();
    // console.log(this.props.buyerProperties)
  }

  propertyDetail = (info) => {
    console.log("this is has to be set", info)
    // localStorage.setItem('SinglePropertyMount', info)
    this.setState({
      data: info
    })
    // this.toggleModal();
    localStorage.setItem("PropertyDetailId", info)
  }



  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  Userinfo = () => {
    this.toggleModalUser()
  }
  toggleModalUser = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }


  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({filteredInfo: null});
  };
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });


  };
  toggleModal = () => {

    this.setState({
      modalOpen: !this.state.modalOpen,

    });
  };
  setAgeSort = () => {
    this.setState({
      sortedInfo: {
        order: 'descend',
        columnKey: 'age',
      },
    });
  };
  getColumnSearchProps = (dataIndex, data) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex} ${data}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        )
        : (
          text
        ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({searchText: ''});
  };


  render() {
    const mystyle = {
      background: "#FF0700",
      borderRadius: "50%"
    };

    let filteredProperties;
    if (this.props.buyerProperties)
    {
      filteredProperties = this.props.buyerProperties.filter((data) => data.propStatus !== "hidden")
    }

    let {sortedInfo, filteredInfo} = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (

      <div>


        <Button type="primary">
          <Link to='/app/buyers' style={{color:'white'}}>

            <AiOutlineLeft style={{marginBottom:2, textAlign:'center'}} />

            <span>Back</span>

          </Link>
        </Button>
        <Button
          onClick={

            () => this.Userinfo()

          }
          className="gx-float-xl-right gx-float-lg-right">
          View Profile

        </Button>
        <Modal
          centered
          onCancel={() => this.toggleModalUser(false)}
          title="Profile Details"
          style={{top: 20}}
          visible={this.state.modalOpen}
          footer={null}
          width={800}
        >

          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <About/>
          </Col>

        </Modal>
        <Card title={this.props.clintProfile && this.props.clintProfile.firstName +" "+this.props.clintProfile.lastName}>
          <ReactDragListView.DragColumn {...this.dragProps}>
          <Table  className="gx-table-responsive" columns={this.state.columns} dataSource={filteredProperties}
                 onChange={this.handleChange}/>
          </ReactDragListView.DragColumn>

        </Card>

      </div>
    );
  }
}

const mapStateToProps = ({property, profile}) => {
  const {data, buyerProperties, loader} = property;
  const {clintProfile} = profile;
  return {data, buyerProperties, loader, clintProfile}
};
export default connect(mapStateToProps, {
  getBuyerProperties,
  getBuyerPropertiesSuccess,
  getClintProfile,
})(GetBuyerProperties);

