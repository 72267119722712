import {GET_ALL_BUYERS,GET_BUYER_PROPERTIES,GET_RECENT_BUYERS } from "constants/ActionTypes";
import axios from 'util/Api';
import {
  getAllBuyers,
  getrecentBuyersSuccess,
  getrecentBuyersError,
  getAllBuyersSuccess,
  getAllBuyersError,
  getBuyerProperties,
  getBuyerPropertiesSuccess,
  getBuyerPropertiesError,
  postRepliesError, postRepliesSuccess
} from "../../appRedux/actions/Property"
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import qs from "qs";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "../actions";
import {POST_REPLIES} from "../../constants/ActionTypes";

const getAllBuyersNowAsync = async () =>
  await axios.get('agent/clients')
    .then((response) => {
      // console.log(response);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }
      else {
        return error;
      }
    });

function* getAllBuyersNow(){
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const property = yield call(getAllBuyersNowAsync);
    if (property.status === 0) {
      yield put(getAllBuyersError(property.message));
    } else if (property.status === 1) {
      yield put(getAllBuyersSuccess(property.data));
    }
    else {
      console.log("else");
      yield put(getAllBuyersError(property.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(getAllBuyersError(error));
  }
}
const getBuyersPropertiesNowAsync = async (id) =>
  await axios.get(`agent/${id}/getbuyerproperty`)
    .then((response) => {
      // console.log(response.data);
      return response.data;
    }).catch(function (error) {
      if(error.response != null) {
        console.log(error.response.data);
        return error.response.data;
      }
      else {
        return error;
      }
    });

function* getBuyerPropertyNow({payload}) {
  const { data } = payload;

  const id=data.toString()
  const { history } = payload;

  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const property = yield call(getBuyersPropertiesNowAsync,id);
    if (property.status === 0) {
      // console.log("id in generator" , data);
      yield put(getBuyerPropertiesError(property.message));
    }
    else if (property.status === 1) {
      yield put(getBuyerPropertiesSuccess(property.data ,history));
      // console.log("Hello undefined" ,data)
      // history.push("/getBuyerProperties")
    }
    else {
      console.log("else");
      yield put(getBuyerPropertiesError(property.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(getBuyerPropertiesError(error));
  }
}

// Post Replies

const postRepliesNowAsync = async (data) =>
  await axios.post('property/postreply',
    qs.stringify({
      propid: data.propertyId,
      reply: data.message,
    })
  ).then((response) => {
    console.log(response);
    return response.data;
  }).catch(function (error) {
    if(error.response != null) {
      console.log(error.response);
      return error.response.data;
    }
    else {
      return error;
    }
  });


function* postRepliesNow({payload}) {
  const { data } = payload;
  console.log(data);
  const { history } = payload;
  console.log(history)
  try {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
    const reply = yield call(postRepliesNowAsync,data);
    if (reply.status === 0) {
      console.log("id in generator" , data);
      yield put(postRepliesError(reply.message));
    }
    else if (reply.status === 1) {
      yield put(postRepliesSuccess(reply.data ,history));
      // console.log("Hello undefined" ,data)
       history.push("/app/comments")
    }
    else {
      console.log("else");
      yield put(postRepliesError(reply.message));
    }
  } catch (error) {
    console.log("catch");
    yield put(postRepliesError(error));
  }
}



//recent buyers
const getrecentBuyersNowAsync = async () =>
    await axios.get('agent/recentBuyers')
        .then((response) => {
            console.log(response);
            return response.data;
        }).catch(function (error) {
            if(error.response != null) {
                console.log(error.response.data);
                return error.response.data;
            }
            else {
                return error;
            }
        });

function* getrecentBuyersNow(){
    try {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
        const property = yield call(getrecentBuyersNowAsync);
        if (property.status === 0) {
            yield put(getrecentBuyersError(property.message));
        } else if (property.status === 1) {
            yield put(getrecentBuyersSuccess(property.data));
        }
        else {
            console.log("else");
            yield put(getrecentBuyersError(property.message));
        }
    } catch (error) {
        console.log("catch");
        yield put(getrecentBuyersError(error));
    }
}



export function* watchgetallproperty() {
  yield takeEvery(GET_ALL_BUYERS, getAllBuyersNow);
}
export function* watchgetBuyerproperty() {
  yield takeEvery(GET_BUYER_PROPERTIES, getBuyerPropertyNow);
}
export function* watchgetrecentbuyers() {
    yield takeEvery(GET_RECENT_BUYERS, getrecentBuyersNow);
}
export function* watchpostReplies() {
  yield takeEvery(POST_REPLIES, postRepliesNow);
}
export default function* rootSaga() {
  yield all([
    fork(watchgetallproperty),
    fork(watchgetBuyerproperty),
    fork(watchgetrecentbuyers),
    fork(watchpostReplies),
  ])

}
