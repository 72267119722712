import React from "react";
import {Avatar} from "antd";

const UserCell = ({chat, chatStateId, onSelectBuyer}) => {
  return (
    <div className={`gx-chat-user-item`} onClick={() => {
      onSelectBuyer(chat._id.toString());
      chatStateId()
    }}>
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Avatar style={{ color: '#fff', backgroundColor: '#7c7e7d' }}
                      className="gx-size-40" alt="">{chat && chat.firstName && chat.firstName.charAt(0)}</Avatar>

            {/*<Avatar src={chat.garage} className="gx-size-40" alt={chat.garage}/>*/}
            {/*<span className={`gx-status gx-small gx-${chat.status}`}/>*/}
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h6">{chat.firstName} {chat.lastName}</span>
          {/*<div className="gx-chat-info-des gx-text-truncate">{chat.price}</div>*/}
          {/*<div className="gx-last-message-time">{chat.lastMessageTime}</div>*/}
        </div>

        {/*{chat.replies.length > 0 ? <div className="gx-chat-date">*/}
        {/*  <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">{chat.replies.length}</div>*/}
        {/*</div> : null}*/}
      </div>
    </div>
  )
};

export default UserCell;
