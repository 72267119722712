import React from "react";
import {Avatar} from "antd";

const UserCell = ({onSelectUser, selectedSectionId, user}) => {

  return (
    <div className={`gx-chat-user-item ${selectedSectionId === user._id ? 'active' : ''}`} onClick={() => {
      onSelectUser(user);
    }}>
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Avatar src={user.garage} className="gx-size-40" alt="Abbott"/>
            {/*<span className={`gx-status ${user.status}`}/>*/}
          </div>
        </div>

        <div className="gx-chat-contact-col">
          <div className="h6 gx-name">{user.address}</div>
          <div className="gx-chat-info-des gx-text-truncate">{user.price}</div>
        </div>
      </div>
    </div>
  )
};

export default UserCell;
