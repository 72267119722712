import React, {Component} from "react";
import {Col, Row} from "antd";
import About from "../../../components/profile/About/index";
import Contact from "../../../components/profile/Contact/index";
import Auxiliary from "../../../util/Auxiliary";
import ProfileHeader from "../../../components/profile/ProfileHeader/index";
import {getAllProfile} from "../../../appRedux/actions/Profile"
import {connect} from "react-redux";
import {
    getDashboard,
} from "../../../appRedux/actions";
class Profile extends Component {

  constructor(props) {
    super(props);

  }
    componentDidMount() {
        this.props.getAllProfile();
        this.props.getDashboard();
    }
  componentWillMount() {
      this.props.getDashboard();
      this.props.getAllProfile();
  }

  render() {
    return (
      <Auxiliary>
        <ProfileHeader/>
        <div className="gx-profile-content">
          <Row>
            <Col xl={16} lg={14} md={14} sm={24} xs={24}>
              <About />
              {/*<Biography/>*/}
              {/*<Events/>*/}
            </Col>

            <Col xl={8} lg={10} md={10} sm={24} xs={24}>
              <Contact/>

            </Col>
          </Row>
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({profile}) => {
  const {data} = profile;
  return {data}
};
export default connect(mapStateToProps, {getAllProfile,getDashboard})(Profile);


