import React, {Component} from "react";
import {Col, Row} from "antd";
import Auxiliary from "util/Auxiliary";
import TotalProperties from "./TotalProperties";
import MyAgentCard from "./MyAgentCard";
import {connect} from "react-redux";
import BuyersList from "./BuyersList";
import SellersList from "./SellersList";
import NameCard from "./NameCard";
import {
    getDashboard,
} from "../../../appRedux/actions";

class CRM extends Component {

    componentDidMount() {
        this.props.getDashboard();
    }

    render() {
        return (
    <Auxiliary>
      <Row>
        <Col span={24}>
          <Row>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <NameCard/>
            </Col>
            <Col xl={8} lg={24} md={8} sm={24} xs={24}>
              <TotalProperties/>
            </Col>
            <Col xl={8} lg={12} md={8} sm={24} xs={24}>
              <MyAgentCard/>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
              <Row>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <BuyersList/>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <SellersList/>
                </Col>
              </Row>

        </Col>
      </Row>


    </Auxiliary>
        );
    }
};

const mapStateToProps = ({auth}) => {
    const {authUser} = auth;
    return {authUser}
};
export default connect(mapStateToProps, {getDashboard} )(CRM);
