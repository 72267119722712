import React from "react";
import {Avatar} from "antd";
import Moment from "moment";

const SentMessageCell = ({conversation}) => {
  return (
    <div className="gx-chat-item gx-flex-row-reverse">

      {/*<Avatar className="gx-size-40 gx-align-self-end" src={conversation.garage}*/}
      {/*        alt={conversation.garage}/>*/}
      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{conversation && conversation.reply}</div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">{Moment(conversation && conversation.time).format('hh:mm A')}</div>
        </div>
      </div>

    </div>
  )
};

export default SentMessageCell;
