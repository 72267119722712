import React from "react";
import {Avatar} from "antd";
import Moment from "moment";
import {connect} from "react-redux";

const ReceivedMessageCell = ({conversation, user, clintProfile}) => {
  console.log("Replies",conversation)
  return (

    conversation ?

    <div className="gx-chat-item">

      <Avatar style={{ color: '#fff', backgroundColor: '#7c7e7d' }}
              className="gx-size-40 gx-mt-3" alt="">{clintProfile && clintProfile.firstName && clintProfile.firstName &&clintProfile.firstName.charAt(0)}</Avatar>

      {/*<Avatar className="gx-size-40 gx-align-self-end" src={authUser.garage}*/}
      {/*        alt=""/>*/}

          <div className="gx-bubble-block">
            <div className="gx-bubble">
              {
                conversation.length === 0 ?
                  <div className="gx-message">

                    No Replies
                  </div>
                  :
                  <div className="gx-message">
                    {conversation && conversation.reply}
                    {/*{conversation && conversation.map((data) => data.replies)}*/}
                  </div>
              }

              <div className="gx-time gx-text-muted gx-text-right gx-mt-2">{Moment(conversation && conversation.time).format('hh:mm A')}</div>
            </div>
          </div>




    </div>
      : ""
  )
};

const mapStateToProps = ({profile}) => {
  const {clintProfile} = profile;
  return {clintProfile}
};
export default connect(mapStateToProps, null)(ReceivedMessageCell);

