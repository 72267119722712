import React from "react";
import {Route, Switch} from "react-router-dom";
import App1 from "./app/index";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}app`} component={App1}/>
    </Switch>
  </div>
);

export default App;
