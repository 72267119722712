// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_AGENT = 'CHANGE_AGENT';
export const CHANGE_AGENT_SUCCESS = 'CHANGE_AGENT_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE='NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS='FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS='UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS='UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS='DELETE_CONTACT_SUCCESS';


//Buyers

export const GET_ALL_BUYERS = 'GET_ALL_BUYERS';
export const GET_ALL_BUYERS_SUCCESS = 'GET_ALL_BUYERS_SUCCESS';
export const GET_ALL_BUYERS_ERROR = 'GET_ALL_BUYERS_ERROR';


//Profile

export const GET_ALL_PROFILE = 'GET_ALL_PROFILE';
export const GET_ALL_PROFILE_SUCCESS = 'GET_ALL_PROFILE_SUCCESS';
export const GET_ALL_PROFILE_ERROR = 'GET_ALL_PROFILE_ERROR';

//OTP verification
export const OTP_VERIFIED = 'OTP_VERIFIED';
export const OTP_VERIFIED_ERROR = 'OTP_VERIFIED_ERROR';

// OTP Resend
export const OTP_RESEND = 'OTP_RESEND'

// get Properties of buyers (from buyer Id)
export const GET_BUYER_PROPERTIES = "GET_BUYER_PROPERTIES";
export const GET_BUYER_PROPERTIES_SUCCESS = "GET_BUYER_PROPERTIES_SUCCESS";
export const GET_BUYER_PROPERTIES_ERROR = "GET_BUYER_PROPERTIES_ERROR";


// Forget Password Email Sending
export const FORGET_PASSWORD_EMAIL = 'FORGET_PASSWORD_EMAIL';
export const FORGET_PASSWORD_EMAIL_SUCCESS = 'FORGET_PASSWORD_EMAIL_SUCCESS';

// Forget Password Verification
export const FORGET_PASSWORD_VERIFICATION = 'FORGET_PASSWORD_VERIFICATION';
export const FORGET_PASSWORD_VERIFICATION_SUCCESS = 'FORGET_PASSWORD_VERIFICATION_SUCCESS';

// Reset Password
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';


export const GET_CLINT_PROFILE="GET_CLINT_PROFILE";
export const GET_CLINT_PROFILE_Success="GET_CLINT_PROFILE_Success";


//recent buyers
export const GET_RECENT_BUYERS = 'GET_RECENT_BUYERS';
export const GET_RECENT_BUYERS_SUCCESS = 'GET_RECENT_BUYERS_SUCCESS';
export const GET_RECENT_BUYERS_ERROR = 'GET_RECENT_BUYERS_ERROR';

//edit Profile

export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';


//Dashboard Call
// Dashboard Call
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';

// REPLIES CALL
export const POST_REPLIES = 'POST_REPLIES';
export const POST_REPLIES_SUCCESS = 'POST_REPLIES_SUCCESS';
export const POST_REPLIES_ERROR = 'POST_REPLIES_ERROR';
