import React, {useEffect,useState} from "react";
import {Button, Row,Col,Card,Modal,Tooltip, Avatar } from "antd";
import {Link ,history} from "react-router-dom";
import {connect} from "react-redux";
import {getBuyerProperties, getBuyerPropertiesSuccess} from "../../../appRedux/actions";
import './propertycss/property.css'
import { AiOutlineCompress,AiFillReconciliation, AiFillCar,AiOutlineLeft,AiOutlineCar,AiTwotoneHome,AiOutlineHome,AiFillRead  } from "react-icons/ai";
import moment from 'moment';

const PropertyDetail = (props) => {
  const PropertyId = localStorage.getItem("PropertyDetailId");
  const [BpDetail,setBpDetail]=useState(props.buyerProperties.filter(p=>p._id == PropertyId))
  function getColorCode(overall) {
    if(overall === ""){
      return '#515756';
    }else if(overall <= 2){
      return '#FF0700';
    }else if(overall <= 4 && overall >= 3){
      return '#FF7800';
    }else if(overall <= 6 && overall >= 5){
      return '#ffce1f';
    }else if(overall <= 8 && overall >= 7){
      return '#FEF100';
    } else if(overall <= 10 && overall >= 9){
      return '#80D726';
    }
  }
  function getNullNumber(overall) {
    if(overall === ""){
      return 'x';
    }else if(overall >= 0){
      return overall;
    }
  }
    function getColor(overall) {
        if(overall <= 2){
            return <Tooltip title={overall}>
              <li style={{color:'#FF0700'}}>
                <i className="icon icon-circle gx-fs-xxs"/>
              </li>
            </Tooltip>
        }else if(overall <= 4 && overall >= 3){
            return <Tooltip title={overall}>
              <li style={{color:'#FF7800'}}>
                <i className="icon icon-circle gx-fs-xxs"/>
              </li>
            </Tooltip>
        }else if(overall <= 6 && overall >= 5){
            return <Tooltip title={overall}>
              <li style={{color:'#ffce1f'}}>
                <i className="icon icon-circle gx-fs-xxs"/>
              </li>
            </Tooltip>
        }else if(overall <= 8 && overall >= 7){
            return <Tooltip title={overall}>
              <li style={{color:'#FEF100'}}>
                <i className="icon icon-circle gx-fs-xxs"/>
              </li>
            </Tooltip>
        } else if(overall <= 10 && overall >= 9){
            return <Tooltip title={overall}>
              <li style={{color:'#80D726'}}>
                <i className="icon icon-circle gx-fs-xxs"/>
              </li>
            </Tooltip>


        }
    }
  return (
      BpDetail.map(item => {
      return  <div>
        <Button type="primary">
          <Link to='/app/listing' style={{color:'white'}}>

            <AiOutlineLeft style={{marginBottom:2, textAlign:'center'}} />

            <span>Back</span>

          </Link>
                </Button>

        <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
          <Col className="gutter-row" span={6} xl={12} lg={12} md={12} sm={24} xs={24} >
              {<span>
            <img alt="Special Edition Party Spas"
                 src={item.garage}
                 style={{height:'440px',width:'450px', borderRadius:5 }}
            />
          </span>}
            <div style={{'margin-left':'18px','margin-top':'25px','border-radius':'10px'}}>

            </div>
          </Col>
          <Col className="gutter-row" span={2} xl={12} lg={12} md={12} sm={24} xs={24} >
            <Card style={{ width: 450 }}>
              <h1 style={{display:'inline'}}><span className="gx-text-primary" style={{fontSize:'24px',fontWeight:'400' }}>{item.price}</span> </h1>
              <span>{item.beds} bed</span> |
              <span>{item.baths} bath</span>  |
              <span>{item.sqft} </span>
              <p style={{marginTop:'8px',}}><strong>Days on Market:</strong> {moment(item.DOMdate).fromNow()} <strong>| Status:</strong> {item.mStatus}</p>
              <p style={{marginTop:'8px', fontWeight:'500'}}>{item.address}</p>
              <a className="gx-text-grey gx-text-underline gx-mb-2" href={item.link} target="_blank"> Link to Property</a>
              <h1 className="gx-text-primary gx-mt-2" style={{ fontSize:'24px'}}>Facts and Features</h1>
              <hr />

              <br />
              <Row>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <AiOutlineHome className="gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Built</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.built}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-company gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Lot Size</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.acres}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className=" AiFillCar icon icon-car gx-fs-xlxl gx-text-primary"/> <AiOutlineCar className="gx-fs-xlxl gx-text-primary" />
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Parking</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.parking}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <i className="icon icon-revenue-new gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Tax</h6>
                      <p className="gx-mb-0">{item.tax}</p>
                    </div>
                  </div>
                </Col>


                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <AiOutlineHome className="gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Stories</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.stories}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <AiFillReconciliation className="gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">HOA</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.hoa}</p>
                    </div>
                  </div>
                </Col>

                <Col xl={14} lg={14} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <AiFillRead className="gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">School Dist</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.schooldist}</p>
                    </div>
                  </div>
                </Col>
                <Col xl={10} lg={10} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                    <div className="gx-mr-3">
                      <AiOutlineCompress className="gx-fs-xlxl gx-text-primary"/>
                    </div>
                    <div className="gx-media-body">
                      <h6 className="gx-mb-1 gx-text-grey">Mls</h6>
                      <p className="gx-mb-0 gx-text-capitalize">{item.mls}</p>
                    </div>
                  </div>
                </Col>

              </Row>




            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="gutter-row" span={2} xl={12} lg={12} md={12} sm={24} xs={24} >
            <Card style={{ width: 450 }}>

              <h1 className="gx-text-primary" style={{ fontSize:'24px'}}>Feedback</h1>
              <hr />

              <br />
              <Row>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>

                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qoverall) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qoverall)}</Avatar>&nbsp; Overall </Row>
                    </p>
                  </div>

                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap ">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qlocation) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qlocation)}</Avatar>&nbsp; Location </Row>
                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap  ">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qprice) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qprice)}</Avatar>&nbsp; Price </Row>
                    </p>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap ">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qhomeage) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qhomeage)}</Avatar>&nbsp; Homage </Row>

                    </p>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qextstyle) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qextstyle)}</Avatar>&nbsp; Exterior Style </Row>

                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap ">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qintstyle) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qintstyle)}</Avatar>&nbsp; Interior Style </Row>

                    </p>
                  </div>
                </Col>

                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qfloorplan) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qfloorplan)}</Avatar>&nbsp; Floor Plan </Row>

                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qkitchen) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qkitchen)}</Avatar>&nbsp; Kitchen </Row>
                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qflooring) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qflooring)}</Avatar>&nbsp; Flooring</Row>
                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qlot) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qlot)}</Avatar>&nbsp; Lot </Row>
                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qsqft) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qsqft)}</Avatar>&nbsp; Sqft </Row>
                    </p>
                  </div>
                </Col>
                <Col xl={8} lg={12} md={12} sm={12} xs={24}>
                  <div className="gx-media gx-flex-nowrap">
                    <p className="gx-ml-1 gx-mb-1">
                      <Row className="gx-ml-1"><Avatar style={{ color: '#fff', backgroundColor: getColorCode(item.question.qparking) }} className="gx-size-20" alt="">{item && getNullNumber(item.question.qparking)}</Avatar>&nbsp; Parking </Row>

                    </p>
                  </div>
                </Col>

              </Row>
              <br />

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <AiFillRead className="gx-fs-xlxl gx-text-primary"/>
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Comments</h6>
                  <p className="gx-mb-0 gx-text-capitalize">{item.question.qcomments ? item.question.qcomments : "N/A"}</p>
                </div>
              </div>
            </Card>

          </Col>
            {item.listdate.length > 0 ?
          <Col className="gutter-row" span={2} xl={12} lg={12} md={12} sm={24} xs={24} >
            <Card style={{ width: 450 }}>

              <h1 className="gx-text-primary" style={{ fontSize:'24px'}}>Listing Provided By</h1>
              <hr />

              <br />

                {item.listdate && item.listdate.map(list => {
                    return  (
                        <div>
                          <Col xl={24} lg={24} md={12} sm={12} xs={24}>
                            <div className="gx-media gx-flex-nowrap gx-mt-4 gx-mt-lg-1 gx-mb-2">
                              <p className="gx-mb-1">
                                  {(list)}
                              </p>
                            </div>
                          </Col>
                        </div>
                    )
                })
                }







            </Card>
          </Col>
                : null}

        </Row>
        </div>
      })



  );
}
const mapStateToProps = ({property}) => {
  const {buyerProperties, loader} = property;
  return {buyerProperties, loader}
};
export default connect(mapStateToProps, {getBuyerProperties,getBuyerPropertiesSuccess})(PropertyDetail);

