import React from "react";
import {Col, Row, Tabs} from "antd";
import Widget from "components/Widget";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const TabPane = Tabs.TabPane;

class About extends React.Component {

  render() {
    const {authUser} = this.props;

    return (
      <Widget title="Profile Information" styleName="gx-card-tabs gx-card-tabs-right gx-card-profile center">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Brokerage & Licensure" key="1">
              {authUser && authUser.agentProfile  ?
            <div className="gx-mb-2">
              <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                      <div className="gx-mr-3">
                        <i className="icon icon-revenue-new gx-fs-xlxl gx-text-primary"/>
                      </div>
                      <div className="gx-media-body">
                        <h6 className="gx-mb-1 gx-text-grey">Brokerage</h6>
                        <p className="gx-mb-0">{authUser && authUser.agentProfile.brokerage}</p>
                      </div>
                    </div>
                  </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                      <div className="gx-mr-3">
                        <i className="icon icon-link gx-fs-xlxl gx-text-primary"/>
                      </div>
                      <div className="gx-media-body">
                        <h6 className="gx-mb-1 gx-text-grey">Brokerage Website</h6>
                        <p className="gx-mb-0">{authUser && authUser.agentProfile.brokerageWebsite}</p>
                      </div>
                    </div>
                  </Col>


                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                      <div className="gx-mr-3">
                        <i className="icon icon-location gx-fs-xlxl gx-text-primary"/>
                      </div>
                      <div className="gx-media-body">
                        <h6 className="gx-mb-1 gx-text-grey">License State</h6>
                        <p className="gx-mb-0">{authUser && authUser.agentProfile.license}</p>
                      </div>
                    </div>
                  </Col>

                <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                      <div className="gx-mr-3">
                        <i className="icon icon-ckeditor gx-fs-xlxl gx-text-primary"/>
                      </div>
                      <div className="gx-media-body">
                        <h6 className="gx-mb-1 gx-text-grey">License No.</h6>
                        <p className="gx-mb-0 gx-text-capitalize">{authUser && authUser.agentProfile.licenseNo}</p>
                      </div>
                    </div>
                  </Col>

              </Row>
            </div>
                  :
                  <div>
                    <h4>Your profile is not completed.</h4>
                    <Link to="editprofile" style={{marginTop:'100px'}}>Click here to complete your profile</Link>
                  </div>

              }
          </TabPane>

          {/*<TabPane tab="Work" key="2">
            <div className="gx-mb-2">
              <Row>{aboutList.map((about, index) =>
                <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                  <AboutItem data={about}/>
                </Col>
              )}
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Education" key="3">
            <div className="gx-mb-2">
              <Row>
                {aboutList.map((about, index) =>

                  <Col key={index} xl={8} lg={12} md={12} sm={12} xs={24}>
                    <AboutItem data={about}/>
                  </Col>
                )}
              </Row>
            </div>
          </TabPane>*/}
        </Tabs>
      </Widget>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(About);
