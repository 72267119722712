import React from "react";
import {Col, Row} from "antd";

import Metrics from "components/Metrics";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
const MyAgentCard = ({authUser}) => {
  return (
    <Metrics styleName={`gx-card-full`} title="Your brokerage">
      <Link to="/app/profile">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="gx-pb-2 gx-pl-4 gx-pt-1">
            <h1 className="gx-fs-xxxl gx-chart-up gx-pt-3" style={{'font-size':'30px'}}>
              {authUser && authUser.agentProfile && authUser.agentProfile.brokerage ? authUser.agentProfile.brokerage:"No BrokerAge"}
            </h1>
            <p className="gx-text-grey gx-font-weight-semi-bold">{authUser && authUser.agentProfile && authUser.agentProfile.brokerageWebsite ? authUser.agentProfile.brokerageWebsite:"Please complete your Profile"}</p>
          </div>
        </Col>
      </Row>
      </Link>
    </Metrics>
  );
};

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};
export default connect(mapStateToProps, null)(MyAgentCard);
