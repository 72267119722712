import {GET_ALL_BUYERS, GET_ALL_BUYERS_SUCCESS, GET_ALL_BUYERS_ERROR, GET_BUYER_PROPERTIES ,GET_BUYER_PROPERTIES_SUCCESS,
  GET_BUYER_PROPERTIES_ERROR,SHOW_MESSAGE,
    GET_RECENT_BUYERS,GET_RECENT_BUYERS_SUCCESS,GET_RECENT_BUYERS_ERROR,} from "constants/ActionTypes";
import {POST_REPLIES, POST_REPLIES_ERROR, POST_REPLIES_SUCCESS} from "../../constants/ActionTypes";


export const getAllBuyers = () => {
  return {
    type: GET_ALL_BUYERS
  };
};export const getAllBuyersSuccess = (data) => {
  return {
    type: GET_ALL_BUYERS_SUCCESS,
    payload:data
  };
};export const getAllBuyersError = (data) => {
  return {
    type: GET_ALL_BUYERS_ERROR,
    payload:data
  };
};
export const getBuyerProperties = (data, history) => {
  return {
    type: GET_BUYER_PROPERTIES,
    payload: {data, history}
  };
};export const getBuyerPropertiesSuccess = (data, history) => {
  return {
    type: GET_BUYER_PROPERTIES_SUCCESS,
    payload:{data,history}
  };
};export const getBuyerPropertiesError = (data) => {
  return {
    type: GET_BUYER_PROPERTIES_ERROR,
    payload:data
  };
};

//rrecent buyers
export const getrecentBuyers = () => {
    return {
        type: GET_RECENT_BUYERS
    };
};export const getrecentBuyersSuccess = (data) => {
    return {
        type: GET_RECENT_BUYERS_SUCCESS,
        payload:data
    };
};export const getrecentBuyersError = (data) => {
    return {
        type: GET_RECENT_BUYERS_ERROR,
        payload:data
    };
};


export const postReplies = (data, history) => {
  return {
    type: POST_REPLIES,
    payload: {data, history}
  };
};export const postRepliesSuccess = (message) => {
  return {
    type: POST_REPLIES_SUCCESS,
    payload: message
  };
};export const postRepliesError = (message) => {
  return {
    type: POST_REPLIES_ERROR,
    payload:message
  };
};


