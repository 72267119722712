import {GET_ALL_PROFILE , GET_ALL_PROFILE_SUCCESS, GET_ALL_PROFILE_ERROR, GET_CLINT_PROFILE,
    GET_CLINT_PROFILE_Success} from "constants/ActionTypes";


const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  data: null,
    clintProfile:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PROFILE: {return {...state, loader: true,}}
    case GET_ALL_PROFILE_SUCCESS: {return {...state, loader: false, data:action.payload}}
    case GET_ALL_PROFILE_ERROR: {return {...state, loader: false , alertMessage:action.payload , showMessage:true}}


      //Get clint profile
      case GET_CLINT_PROFILE:
          return {
              ...state,
              alertMessage: '',
              showMessage: true,
              loader: true
          }
      case GET_CLINT_PROFILE_Success:
          return {
              ...state,
              alertMessage: '',
              showMessage: false,
              loader: false,
              clintProfile:action.payload
          }


    default:
      return state;
  }
}
