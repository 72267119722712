import {GET_ALL_PROFILE , GET_ALL_PROFILE_SUCCESS, GET_ALL_PROFILE_ERROR,GET_CLINT_PROFILE,GET_CLINT_PROFILE_Success,
    SHOW_MESSAGE} from "constants/ActionTypes";


export const getAllProfile = () => {
  return {
    type: GET_ALL_PROFILE
  };
};export const getAllProfileSuccess = (data) => {
  return {
    type: GET_ALL_PROFILE_SUCCESS,
    payload:data
  };
};export const getAllProfileError = (data) => {
  return {
    type: GET_ALL_PROFILE_ERROR,
    payload:data
  };
};
export const showMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};
export const getClintProfile = (data) => {
    return {
        type: GET_CLINT_PROFILE,
        payload: data
    };
};

export const getClintProfileSuccess = (data) => {
    return {
        type: GET_CLINT_PROFILE_Success,
        payload: data
    };
};
