import React from 'react'
import {Table, Input, Button, Icon, Pagination, Row, Col} from 'antd';
import Highlighter from 'react-highlight-words';
import {connect} from "react-redux";
import {getAllBuyers, getAllBuyersSuccess} from "../../../appRedux/actions";
import {getBuyerProperties,getBuyerPropertiesSuccess} from "../../../appRedux/actions/Property";
import {Link ,history} from "react-router-dom";
import GetBuyerProperties from "./getBuyerProperties";
import {AiOutlineLeft} from "react-icons/ai";

class BuyersListData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    searchText: '',
    searchedColumn: '',
    buyerPropertyId: '',
  };
  }
  componentDidMount() {
    this.props.getAllBuyers();
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      )
        : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  handlePropertyBtn = (id)=>{
    localStorage.setItem('buyerProporties', id);
  }
  render() {

    const columns = [{
      title: 'First Name',
      dataIndex: 'firstName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      ...this.getColumnSearchProps('firstName'),

      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    },{
      title: 'Last Name',
      dataIndex: 'lastName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      ...this.getColumnSearchProps('lastName'),

      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    }, {
      title: 'Email',
      dataIndex: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...this.getColumnSearchProps('email'),

      render: (phone) => {
        return <span className="h6">{phone}</span>
      },
    }, {
      title: 'Phone',
      dataIndex: 'buyerProfile.phone',
      ...this.getColumnSearchProps('buyerProfile.phone'),
      render: (phone) => {
        return <span className="h6">{phone ? phone : "N/A"}</span>
      },
    },

      {
      title: 'Listing',
      dataIndex: '_id',
      key: '_id',

      render: (id) => {
        return <Button type="primary" onClick={() => this.handlePropertyBtn(id)}><Link to="listing">Browse</Link></Button>
      },
    }
    ];
    return (<div><Button type="primary">
      <Link to='/app/dashboard' style={{color:'white'}}>

          <AiOutlineLeft style={{marginBottom:2, textAlign:'center'}} />

        <span>Back</span>

      </Link>
    </Button><Table columns={columns} dataSource={this.props.data}
                  pagination={{pageSize: 10}}
    /></div>)
  }
}

const mapStateToProps = ({property}) => {
  const {data, buyerProperties,loader} = property;
  return {data,buyerProperties, loader}
};
export default connect(mapStateToProps, {getBuyerProperties,getBuyerPropertiesSuccess,getAllBuyers,getAllBuyersSuccess})(BuyersListData);
