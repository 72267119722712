import React from "react";
import {Card} from "antd";
import {Table} from 'reactstrap';
import {getAllBuyers} from "../../../appRedux/actions";
import {connect} from "react-redux";
import Widget from "../../../components/Widget";
class BuyersList extends React.Component {

  componentDidMount() {
    this.props.getAllBuyers();
  }

  render() {
    const sellers = null;
    const columns = [{
      title: 'First Name',
      dataIndex: 'firstName',
    },{
      title: 'Last Name',
      dataIndex: 'lastName',
    }, {
      title: 'Email',
      dataIndex: 'email',
    }, {
      title: 'Phone',
      dataIndex: 'buyerProfile.phone',
      render: (phone) => {
        return <span className="gx-text-grey">{phone}</span>
      },
    }];

    return (
      <Widget
        title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Sellers</h2>
        } extra={
        <p className="gx-text-primary gx-mb-0 gx-pointer gx-d-none gx-d-sm-block">
          <i className="icon icon-forward gx-fs-lg gx-d-inline-flex gx-vertical-align-middle"/> Seller Dashboard</p>
      }>
        <div className="h6">
          <Table hover size="md" style={{color:'#535353', fontSize:'11px'}}>
            <thead>
            <tr >
              <td>FIRST NAME</td>
              <td>LAST NAME</td>
              <td>EMAIL</td>
              <td>PHONE</td>
            </tr>
            </thead>
          </Table>
          <div className="text-center">
            <span style={{color:'#535353'}}>No Data Found</span>
          </div>

        </div>
      </Widget>
    );
  }

}


const mapStateToProps = ({property}) => {
  const {data, loader} = property;
  return {data, loader}
};
export default connect(mapStateToProps, {getAllBuyers})(BuyersList);
