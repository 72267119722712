import React, {Component} from "react";
import {Button, Checkbox, Form, Icon, Input, message,Modal} from "antd";
import CircularProgress from "../../components/CircularProgress/index";
import {
  userChangePassword,
  hideMessage,
  showAuthLoader,
  otpVerified,
  otpResend,
  forgetPasswordEmail,
  forgetPasswordVerification
} from "../../appRedux/actions/Auth";
import {connect} from "react-redux";
import IntlMessages from "util/IntlMessages";
import {Link} from "react-router-dom";

const FormItem = Form.Item;


class ForgetPasswordOTP extends Component {

  state = {
    confirmDirty: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        this.props.showAuthLoader();
        this.props.forgetPasswordVerification(values,this.props.history);
      }
    });

  };
  handleResendOTP = () => {
    this.props.forgetPasswordEmail(localStorage.getItem('emailForForgetPassword'),this.props.history);
  }
  info= () =>{
    Modal.info({
      title: 'OTP Resent',
      content: (
        <div>
          <p>We have Resent OTP on {localStorage.getItem('emailForForgetPassword')}</p>
        </div>
      ),
    });
  }
  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
        this.props.form.resetFields();
      }, 100);
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  }

  render() {

    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;
    return (
      <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header gx-text-center">
            <h1 className="gx-login-title gx-text-primary gx-font-weight-bold">Enter Verification Code</h1>
          </div>
          <center><h5>We have sent you a verification code, please check your mailbox and verify code to reset your password!</h5></center>
          <center><p style={{color:'#54b579'}}>{localStorage.getItem('emailForForgetPassword')}</p></center>


          <Form onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">
            <FormItem hidden>
              {getFieldDecorator('email', {
                initialValue: localStorage.getItem('emailForForgetPassword'),
                rules: [{
                  required: true, type: 'email', message: 'The input is not valid E-mail!',
                }],
              })(
                <Input name="email" placeholder="Email"/>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('otp', {
                rules: [{
                  required: true, message: 'The input is not valid OTP!',
                }],
              })(
                <Input name="otp" placeholder="Enter Verification Code"/>
              )}
            </FormItem>
            {/*{loader ?
              <div>
                <CircularProgress className={'gx-loader-view'}/>
              </div> : null}*/}
            {showMessage ?
              message.info(alertMessage.toString()) : null}
            <FormItem className="gx-text-center">
              <Button loading={loader} type="primary" className="gx-mb-0" htmlType="submit">Verify</Button>
              <Link to="/forgetPasswordOTP" onClick={this.handleResendOTP} style={{marginTop:'100px'}}>Resend Code</Link>

            </FormItem>



          </Form>

        </div>
      </div>
    );
  }
}
const WrappedNormalOTPForm = Form.create()(ForgetPasswordOTP);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};
export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  otpVerified,
  otpResend,
  forgetPasswordEmail,
  forgetPasswordVerification
})(WrappedNormalOTPForm);
