import React from "react";
import Widget from "components/Widget";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Contact = ({authUser}) => {
    return (
        <Widget title="Contact Information" styleName="gx-card-profile-sm  gx-card-profile">
          <div className="gx-media gx-align-items-center gx-flex-nowrap gx-pro-contact-list">
              {authUser && authUser.agentProfile ?
                 <div>
                  <div className="gx-mr-3">
                    <i className="icon icon-phone gx-fs-xlxl gx-text-primary"/>
                  </div>
                  <div className="gx-media-body">
                      <span className="gx-mb-0 gx-text-grey gx-fs-sm">Phone</span>
                  <p className="gx-mb-0">
                  {authUser && authUser.agentProfile.phone}
                  </p>
                  </div>
                 </div>
                  :
                  <div>
                  <h4>Your profile is not completed.</h4>
                  <Link to="editprofile" style={{marginTop:'100px'}}>Click here to complete your profile</Link>
                  </div>}

          </div>

        </Widget>
    )
}

const mapStateToProps = ({auth}) => {
    const {authUser} = auth;
    return {authUser}
};
export default connect(mapStateToProps, null)(Contact);
