import React from "react";
import UserCell from "./UserCell/index";

const ChatBuyerList = ({chatUsers, chatStateId, onSelectBuyer}) => {
  // console.log("Chat Users",chatUsers)
  // console.log(selectedSectionId)
  // console.log(onSelectUser)
  // if (chatUsers.length === 0)
  // {
  //   return <div className="gx-p-5 gx-text-center">No Records Found</div>
  // }

  // console.log("Recent Properties", chatUsers)

  return (
    <div className="gx-chat-user">
      {chatUsers.map((chat, index) =>
        <UserCell key={index} chat={chat} chatStateId={chatStateId} onSelectBuyer={onSelectBuyer}/>
      )}
    </div>
  )
};

export default ChatBuyerList;
