import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {userSignOut} from "appRedux/actions/Auth";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {connect} from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
            {/*<AppsNavigation/>*/}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">


                <Menu.Item key="app/dashboard">
                  <Link to="/app/dashboard"><i className="icon icon-dasbhoard"/>
                    <IntlMessages id="sidebar.dashboard"/>
                  </Link>
                </Menu.Item>

                <Menu.Item key="app/profile">
                  <Link to="/app/profile"><i className="icon icon-profile2"/>
                    <IntlMessages id="sidebar.extensions.profile"/>
                  </Link>
                </Menu.Item>

                {/*<Menu.Item key="app/setting">*/}
                {/*  <Link to="/app/setting"><i className="icon icon-setting"/>*/}
                {/*    <IntlMessages id="sidebar.extensions.setting"/>*/}
                {/*  </Link>*/}
                {/*</Menu.Item>*/}

              <Menu.Item key="app/comments">
                <Link to="/app/comments">
                  <i className="icon icon-chat"/>
                  <span>Comments</span>
                  {/*<IntlMessages id="sidebar.extensions.setting"/>*/}
                </Link>
              </Menu.Item>

                <Menu.Item key="app/logout">
                  <Link to="/signin" onClick={() => this.props.userSignOut()}><i className="icon icon-signin"/>
                    <IntlMessages id="app.userAuth.logout"/>
                  </Link>
                </Menu.Item>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps,{userSignOut})(SidebarContent);

